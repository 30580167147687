import React, { Component } from 'react';
import { NavHashLink } from 'react-router-hash-link';
import styles from '../../styles/modules/navLinks.module.scss';
import Picture from './picture/picture';

const scrollWithOffset = (el) => {
  let offset = 100;
  if (document.getElementById('navigation')) offset = document.getElementById('navigation').offsetHeight + 20;

  const elementPosition = el.offsetTop - offset;
  window.scrollTo({
    top: elementPosition,
    left: 0,
    behavior: 'smooth',
  });
};

const NavLinks = ({ dark, navOpen, toggleNav }) => {
  return (
    <div className={`${styles.nav_menu} ${navOpen ? styles.nav_menu__open : ''}`}>
      <NavHashLink
        smooth
        scroll={(el) => scrollWithOffset(el)}
        className={`${styles.link} ${dark ? styles.link__dark : ''}`}
        to='/#about_us'
        onClick={toggleNav}>
        <div className={styles.link_inner}>
          <div className={styles.link_inner_icon}>
            <Picture src='icons/about' ext='png' alt='About us' />
          </div>
          <p className='font-weight-semi-bold'>Who we are</p>
        </div>
      </NavHashLink>
      <NavHashLink
        smooth
        // scroll={(el) => scrollWithOffset(el)}
        className={`${styles.link} ${dark ? styles.link__dark : ''}`}
        to='/#vision'
        onClick={toggleNav}>
        <div className={styles.link_inner}>
          <div className={styles.link_inner_icon}>
            <Picture src='icons/services' ext='png' alt='About us' />
          </div>
          <p className='font-weight-semi-bold'>What we do</p>
        </div>
      </NavHashLink>
      <NavHashLink
        smooth
        scroll={(el) => scrollWithOffset(el)}
        className={`${styles.link} ${dark ? styles.link__dark : ''}`}
        to='/#partners'
        onClick={toggleNav}>
        <div className={styles.link_inner}>
          <div className={styles.link_inner_icon}>
            <Picture src='icons/partners' ext='png' alt='About us' />
          </div>
          <p className='font-weight-semi-bold'>Partners</p>
        </div>
      </NavHashLink>
      <NavHashLink
        smooth
        scroll={(el) => scrollWithOffset(el)}
        className={`${styles.link} ${dark ? styles.link__dark : ''}`}
        to='/#careers'
        onClick={toggleNav}>
        <div className={styles.link_inner}>
          <div className={styles.link_inner_icon}>
            <Picture src='icons/careers' ext='png' alt='About us' />
          </div>
          <p className='font-weight-semi-bold'>Careers</p>
        </div>
      </NavHashLink>
      <NavHashLink
        smooth
        scroll={(el) => scrollWithOffset(el)}
        className={`${styles.link} ${dark ? styles.link__dark : ''}`}
        to='/#contact'
        onClick={toggleNav}>
        <div className={styles.link_inner}>
          <div className={styles.link_inner_icon}>
            <Picture src='icons/contact' ext='png' alt='About us' />
          </div>
          <p className='font-weight-semi-bold'>Contact</p>
        </div>
      </NavHashLink>
    </div>
  );
};

export default NavLinks;
