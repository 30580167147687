import React, { Component } from 'react';
import { NavHashLink } from 'react-router-hash-link';
import NavLinks from '../common/navLinks';
import { Logo } from '../images/svg';

import styles from '../../styles/modules/navigation.module.scss';

class Navigation extends Component {
  state = {
    navOpen: false,
  };

  toggleNav = () => {
    this.setState({ navOpen: !this.state.navOpen });
  };

  render() {
    return (
      <nav className={styles.nav_container}>
        <NavHashLink smooth className={styles.nav_logo} to='/#home' onClick={() => this.setState({ navOpen: false })}>
          <Logo />
        </NavHashLink>

        <div className={styles.nav_button} onClick={this.toggleNav}>
          <div className={styles.circle}></div>
          <div className={styles.circle}></div>
          <div className={styles.circle}></div>
        </div>

        <NavLinks dark={this.props.dark} navOpen={this.state.navOpen} toggleNav={() => this.toggleNav()} />
      </nav>
    );
  }
}

export default Navigation;
