import React from 'react';

const Picture = ({ src, ext, alt, extraClass, ...rest }) => {
  const webp = exists(`${src}.webp`);
  const webp2x = exists(`${src}@2x.webp`);
  const std = exists(`${src}.${ext}`);
  const std2x = exists(`${src}@2x.${ext}`);

  return (
    <picture>
      {!(webp && webp2x) || <source type='image/webp' srcSet={`${webp2x} 2x, ${webp} 1x`} />}
      {!(std && std2x) || <source srcSet={`${std2x} 2x, ${std} 1x`} />}
      <img {...rest} src={std} alt={alt} className={`image-show ${extraClass}`} />
    </picture>
  );
};

function exists(img) {
  try {
    return require(`../../../images/${img}`);
  } catch {
    return false;
  }
}

export default Picture;
