import React from 'react';
import styles from '../../styles/modules/home/about.module.scss';

const About = () => {
  return (
    <section id='about_us' className={styles.about}>
      <div className={`container-fixed-width ${styles.about_container}`}>
        <div className={styles.title_container}>
          <div className={`line ${styles.line}`}></div>
          <h2 className='color-dark'>About us</h2>
        </div>
        <p className='font-italic color-dark'>
          Elpis, created by passionate people who believe in change and know how to bring the technological evolution at
          the service of their customer.
        </p>
      </div>
    </section>
  );
};

export default About;
