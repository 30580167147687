import React, { Component } from 'react';
import Header from '../home/header';
import About from '../home/about';
import Vision from '../home/vision';

class Home extends Component {
  state = {};

  componentWillMount() {
    window.addEventListener('click', this.handleClick);
  }

  componentWillUnmount() {
    window.removeEventListener('click', this.handleClick);
  }

  handleClick = () => {
    this.props.history.replace('', '');
  };

  render() {
    return (
      <React.Fragment>
        <Header />
        <About />
        <Vision />
        <div style={{ height: '100vh', background: 'gray' }}></div>
      </React.Fragment>
    );
  }
}

export default Home;
