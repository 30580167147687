import React, { Component } from 'react';
import Navigation from '../top/navigation';
import Picture from '../common/picture/picture';
import Spotlight from './spotlight';

import styles from '../../styles/modules/header.module.scss';

class Header extends Component {
  state = {
    navDark: false,
    headerLoaded: false,
    mobileHeaderLoaded: false,
    mobile: window.innerWidth < 768,
  };

  componentDidMount() {
    window.addEventListener('resize', this.handleResize);
    window.addEventListener('scroll', this.handleScroll);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize);
    window.removeEventListener('scroll', this.handleScroll);
  }

  handleScroll = () => {
    const el = document.getElementById('header_bottom');
    const doc = document.documentElement;
    const top = (window.pageYOffset || doc.scrollTop) - (doc.clientTop || 0);

    if (el) {
      if (window.innerHeight - 1.15 * el.offsetHeight >= top && this.state.navDark) this.setState({ navDark: false });
      else if (window.innerHeight - 1.25 * el.offsetHeight < top && !this.state.navDark)
        this.setState({ navDark: true });
    }
  };

  handleResize = () => {
    const mobile = window.innerWidth < 768;
    if (this.state.mobile !== mobile) this.setState({ mobile, mobileHeaderLoaded: false, headerLoaded: false });
  };

  headerLoaded = () => {
    if (this.state.mobile) this.setState({ mobileHeaderLoaded: true, headerLoaded: false });
    else this.setState({ mobileHeaderLoaded: false, headerLoaded: true });
  };

  render() {
    const { size } = this.props;
    const { navDark, mobile, mobileHeaderLoaded, headerLoaded } = this.state;

    return (
      <header id='home' style={{ height: size }} className={styles.header}>
        <div className={styles.header_loader}>
          <Picture
            src={mobile ? 'header-mobile' : 'header'}
            ext='jpg'
            alt={`${mobile ? 'Mobile ' : ''}Header Loader`}
            onLoad={this.headerLoaded}
          />
        </div>

        <div
          className={`${styles.header_background} ${
            (mobileHeaderLoaded || headerLoaded) && 'headerImage__loaded'
          }`}></div>

        <div className={styles.header_top} id='navigation'>
          <div className={`${styles.header_top__content} ${navDark ? styles.header_top__content__dark : ''}`}>
            <Navigation dark={navDark} />
          </div>
        </div>

        <div className={styles.header_spotlight}>
          <Spotlight />
        </div>

        <div className={styles.header_bottom} id='header_bottom'></div>
      </header>
    );
  }
}

export default Header;
