import React from 'react';
import styles from '../../styles/modules/home/vision.module.scss';
import { Share, Introduce, Adapt } from '../images/svg';

const Vision = () => {
  return (
    <section id='vision' className={styles.vision}>
      <div className={`container-fixed-width ${styles.vision_container}`}>
        <div className={styles.title_container}>
          <h2 className='color-dark'>Our Vision & Mission</h2>
          <div className={`line ${styles.line}`}></div>
        </div>
        <div className={styles.content_block}>
          <h3>Vision</h3>
          <p>
            Elpis' vision is to become a European leader in PPM services by bringing added value in the most cost
            efficient way to our customers.
          </p>
        </div>
        <div className={styles.content_block}>
          <h3>Mission</h3>
          <div className={styles.values}>
            <div className={styles.value}>
              <div className={styles.value_icon}>
                <Share />
              </div>
              <p className='font-italic'>
                <span className='font-weight-semi-bold'>Share</span> our extended experience in PPM services, to enhance
                collaboration and flexibility within our customer’s environment.
              </p>
            </div>
            <div className={styles.value}>
              <div className={styles.value_icon}>
                <Introduce />
              </div>
              <p className='font-italic'>
                <span className='font-weight-semi-bold'>Introduce</span> a Center of Excellence and Expertise that will
                bring to our clients a revolution within their business and sustainability for their future.
              </p>
            </div>
            <div className={styles.value}>
              <div className={styles.value_icon}>
                <Adapt />
              </div>
              <p className='font-italic'>
                <span className='font-weight-semi-bold'>Adapt</span> the market’s standards to our client’s needs, by
                simplifying, sharing and mitigating risks and facilitating communication within their
                business environments.
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Vision;
