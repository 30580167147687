import React, { Component } from 'react';
import styles from '../../styles/modules/home/spotlight.module.scss';

const spotlights = [
  { title: 'Digital Innovation', description: ['Transformation at the Digital Age.'] },
  {
    title: 'PPM Services',
    description: ['Everything from setup to maturity for', 'your portfolio, program and projects.'],
  },
  { title: 'Rollout and Maintenance', description: ['Years of experience bringing security to your targets.'] },
];

class Spotlight extends Component {
  state = {
    active: 1,
    reverse: false,
  };

  componentDidMount() {
    setInterval(this.rotateSlide, 4000);
  }

  rotateSlide = () => {
    const { reverse, active } = this.state;
    if (active === 1) this.setState({ reverse: true, active: 0 });
    else if (active === -1) this.setState({ reverse: false, active: 0 });
    else if (reverse) this.setState({ active: active - 1 });
    else if (!reverse) this.setState({ active: active + 1 });
  };

  render() {
    const { active } = this.state;

    return (
      <div className={styles.spotlight}>
        <div className={styles.container} style={{ transform: `translateX(${active * 100}vw)` }}>
          {spotlights.map((spotlight) => (
            <div key={spotlight.title} className={styles.block}>
              <p className={`color-white font-shadow ${styles.title}`}>{spotlight.title}</p>
              {spotlight.description.map((d, key) => (
                <p key={key} className='color-white font-weight-thin'>
                  {d}
                </p>
              ))}
            </div>
          ))}
        </div>
        <div className={styles.bullets}>
          {spotlights.map((spotlight, key) => (
            <div
              key={spotlight.title}
              className={`${styles.dot} ${active === 1 - key ? styles.dot__active : ''}`}
              onClick={() => this.setState({ active: 1 - key, reverse: true })}></div>
          ))}
        </div>
      </div>
    );
  }
}

export default Spotlight;
