import React from 'react';
import { withRouter, Route, BrowserRouter, Switch, Redirect } from 'react-router-dom';
import E404 from './components/errors/e404';
import Home from './components/top/home';

function App() {
  return (
    <BrowserRouter>
      <Switch>
        <Route exact path='/page-not-found' render={(props) => <E404 {...props} />} />

        <Route exact path='/' render={(props) => <Home {...props} />} />

        <Redirect to='/page-not-found' />
      </Switch>
    </BrowserRouter>
  );
}

export default withRouter(App);
